<template>
  <section class="w1220">
    <div class="snavi">
      <img class="mr10 ml10" src="@/assets/img/snavhomeicon.png" /><img src="@/assets/img/snavicon.png" />
      <span class="ml10 b">경기결과</span>
    </div>
    <h2 class="subBanner"><img src="@/assets/img/subBanner4.png" alt=""/></h2>
    <div class="boardwrap">
      <nav-side :currentMenu="currentName" :menuList="menuList"/>
      <router-view />
    </div>
  </section>

</template>

<script>
import NavSide from '@/components/common/NavSide'

export default {
  name: 'Result',
  components: {
    NavSide
  },
  data () {
    return {
      currentName: '',
      menuList: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.getCurrentName()
      }
    }
  },
  created () {
    this.getCurrentName()
    this.setMenuList()
  },
  methods: {
    getCurrentName () {
      if (this.$route.matched) {
        const item = this.$route.matched[this.$route.matched.length - 2]
        if (item) {
          this.currentName = item.name
        }
      }
    },
    setMenuList () {
      const list = this.$router.getRoutes()

      for (let i = 0, iLen = list.length; i < iLen; i++) {
        const item = list[i]
        const routeName = item.name
        if (routeName === 'result') {
          const children = item.children
          for (let j = 0, jLen = children.length; j < jLen; j++) {
            const subItem = children[j]
            if (subItem.meta.lnb) {
              this.menuList.push(subItem)
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
